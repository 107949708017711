import * as React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import cn from 'classnames'
import { UpdateDonutEmptyState } from './empty-state'
import * as HoverCard from '@radix-ui/react-hover-card'
import { UpdateHoverCard } from 'components/update-hover-card'
import { UpdateDonutVm } from './update-donut-vm'
import { UpdateCounts, UpdateType } from '../../types/updates'

export type UpdateDonutProps = {
  updateCounts: UpdateCounts
  includedUpdateTypes?: UpdateType[]
  showEmptyText?: boolean
}

export const UpdateDonut: React.VFC<UpdateDonutProps> = (props) => {
  const { updateCounts, includedUpdateTypes, showEmptyText } = props

  const vm = new UpdateDonutVm(includedUpdateTypes, updateCounts)

  if (vm.totalUpdateCount === 0)
    return <UpdateDonutEmptyState showEmptyText={showEmptyText} />

  const fillColor: Record<UpdateType, string> = {
    actions: 'text-green-600',
    checkins: 'text-pink-600',
    feedback_received: 'text-yellow-600',
    feedback_requested: 'text-yellow-600',
    focus_skills: 'text-green-600',
    notes: 'text-blue-800',
    promotions: 'text-orange-400',
    wins: 'text-green-400',
  }

  return (
    <HoverCard.Root openDelay={100}>
      <HoverCard.Trigger asChild>
        <div className="flex flex-row items-center gap-x-1 relative">
          <PieChart
            width={18}
            height={18}
            className="flex !cursor-pointer"
            margin={{ bottom: 0, top: 0, left: 0, right: 0 }}
          >
            <Pie
              data={vm.filteredData}
              cx="50%"
              cy="50%"
              innerRadius={6}
              outerRadius={8}
              stroke=""
              dataKey="value"
              isAnimationActive={false}
            >
              {vm.filteredData.map((entry) => (
                <Cell
                  key={`cell-${entry.name}`}
                  className={cn('fill-current', fillColor[entry.name])}
                />
              ))}
            </Pie>
          </PieChart>
          <span className="text-gray-900">{vm.totalUpdateCount}</span>
        </div>
      </HoverCard.Trigger>
      <HoverCard.Content asChild>
        <UpdateHoverCard
          updateCounts={updateCounts}
          includedUpdateTypes={includedUpdateTypes}
        />
      </HoverCard.Content>
    </HoverCard.Root>
  )
}
