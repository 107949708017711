import { UpdateCounts, UpdateType } from '../../types/updates'

export class UpdateDonutVm {
  constructor(
    private includedUpdateTypes: UpdateType[] | undefined,
    private updateCounts: UpdateCounts
  ) {}

  get totalUpdateCount() {
    return this.filteredUpdateCounts.reduce<number>(
      (acc, count) => acc + (count || 0),
      0
    )
  }

  get filteredData(): { name: UpdateType; value: number | undefined }[] {
    const data = [
      { name: 'wins', value: this.updateCounts.winsReceivedCount },
      { name: 'actions', value: this.updateCounts.actionsCompletedCount },
      {
        name: 'feedback_received',
        value: this.updateCounts.feedbackReceivedCount,
      },
      { name: 'notes', value: this.updateCounts.notesCount },
    ] as const

    return data.filter((item) => this.showUpdate(item.name))
  }

  private showUpdate(updateType: UpdateType) {
    // show all update types if there is no filtering in place
    if (!this.includedUpdateTypes || this.includedUpdateTypes.length === 0)
      return true

    return this.includedUpdateTypes.includes(updateType)
  }

  private get filteredUpdateCounts() {
    return [
      this.showUpdate('actions') ? this.updateCounts.actionsCompletedCount : 0,
      this.showUpdate('feedback_received')
        ? this.updateCounts.feedbackReceivedCount
        : 0,
      this.showUpdate('notes') ? this.updateCounts.notesCount : 0,
      this.showUpdate('wins') ? this.updateCounts.winsReceivedCount : 0,
    ]
  }
}
