import * as React from 'react'

export type UpdateDonutEmptyStateProps = {
  showEmptyText?: boolean
}

export const UpdateDonutEmptyState: React.VFC<UpdateDonutEmptyStateProps> = (
  props
) => {
  const { showEmptyText = true } = props

  return (
    <div className="flex flex-row items-center gap-x-1 text-sm text-gray-900">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
      >
        <circle
          cx="8"
          cy="8"
          r="7.25"
          strokeWidth="1.5"
          className="stroke-current text-gray-100"
        />
      </svg>
      {showEmptyText && <span className="whitespace-nowrap">No updates</span>}
    </div>
  )
}
